import React, { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import "./style.css";
import { apis } from "../../../apis";

const Index = () => {
  const [banks, setBanks] = useState([]);
  const navigate = useNavigate();

  const onBack = () => {
    navigate("/account");
  };

  const getBanks = async () => {
    const banks = await apis.getBanks();
    setBanks(banks.data);
  };

  const [form, setForm] = useState({
    name: "",
    bankNumber: "",
    bankId: "",
  });

  const onFieldChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onConfirm = async (e) => {
    e.preventDefault();
    try {
      const resp = await apis.setUserBank(form);
      if (resp.data) {
        toast.success("Tạo ngân hàng thành công");
      }
    } catch (error) {
      toast.error(
        "Yêu cầu của bạn không thành công vui lòng liên hệ CSKH để được hỗ trợ"
      );
    }
  };

  useEffect(() => {
    getBanks();
  }, []);

  return (
    <div className="sub-page page-container">
      <div className="sub-page-header">
        <button className="btn btn-light" onClick={onBack}>
          <FaArrowLeftLong /> Trở lại
        </button>
        <div className="text-bold">Thông tin tài khoản</div>
      </div>

      <div className="sub-page-content">
        <form onSubmit={onConfirm}>
          <div className="form-group">
            <label>Tên ngân hàng</label>
            <select
              className="form-control bank-list"
              onChange={onFieldChange}
              required
              name="bankId"
              value={form.bankId}
            >
              <option value="" hidden></option>
              {banks.map((bank) => (
                <option value={bank.id}>{bank.name}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Họ và tên</label>
            <input
              className="form-control"
              required
              placeholder="Nhập họ và tên thật của bạn"
              value={form.name}
              onChange={onFieldChange}
              name="name"
            />
          </div>
          <div className="form-group">
            <label>Số tài khoản</label>
            <input
              className="form-control"
              type="number"
              required
              placeholder="Nhập số tài khoản ngân hàng"
              value={form.bankNumber}
              onChange={onFieldChange}
              name="bankNumber"
            />
          </div>
          <div className="form-bottom">
            <button className="btn btn-primary">Xác nhận liên kết thẻ</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Index;
