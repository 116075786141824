import React, { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

import "./style.css";
import { apis } from "../../../apis";
import { transactionStatus } from "../../../helpers";
import classNames from "classnames";
import moment from "moment";

const Index = () => {
  const [history, setHistory] = useState([]);
  const [userTier, setUserTier] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const navigate = useNavigate();

  const onBack = () => {
    navigate("/account");
  };

  const getHistory = async () => {
    const resp = await apis.missionHistory();
    setHistory(resp.data);
  };

  const getUserTier = async () => {
    const resp = await apis.getUserTier();
    setUserTier(resp.data);
  };

  const onConfirm = () => {
    setIsOpenModal(false);
    getUserTier();
  };

  const onShowModal = (mission) => () => {
    console.log(mission, userTier);
    if (userTier?.pointPending && !mission.isSuccess) {
      setIsOpenModal(true);
    }
  };

  useEffect(() => {
    getHistory();
    onConfirm();
  }, []);

  return (
    <div className="sub-page page-container">
      <div className="sub-page-header">
        <button className="btn btn-light" onClick={onBack}>
          <FaArrowLeftLong /> Trở lại
        </button>
        <div className="text-bold">Lịch sử đặt hàng</div>
      </div>

      <div className="sub-page-content">
        {!!history.length && (
          <div>
            {history.map((item) => (
              <div
                key={item.id}
                className="card mt-2"
                onClick={onShowModal(item)}
              >
                <div className="card-body">
                  <div className="text-bold">{item.mission.name}</div>

                  <div className="mission-item">
                    <div className="mission-item-left">
                      <div>
                        Mã giao dịch:{" "}
                        <span className="text-bold">{item.id}</span>
                      </div>
                      <div>
                        {item.isSuccess ? "Số điểm" : "Số điểm chờ thanh toán"}{" "}
                        {item.isSuccess && (
                          <span className="text-bold">{item.point}</span>
                        )}
                      </div>
                      <div>
                        Thời gian:{" "}
                        {moment(item.createdAt).format("YYYY-MM-DD hh:mm:ss")}
                      </div>
                    </div>
                    <div
                      className={classNames({
                        "mission-item-right": true,
                        danger: !item.isSuccess,
                      })}
                    >
                      <div className="mission-item-image">
                        <img
                          src={
                            window.baseUrlSocket +
                            "/photos/" +
                            item.mission.image?.fileName
                          }
                          alt={item.mission.name}
                        />
                      </div>
                      {item.isSuccess ? "Đã hoàn thành" : "Chờ thanh toán"}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {!history.length && (
          <div className="text-center">Không có lịch sử gửi đơn</div>
        )}
      </div>
      {isOpenModal && (
        <div
          className={classNames({
            modal: true,
            active: isOpenModal,
          })}
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                Số điểm cần quy đổi là{" "}
                <span className="text-danger">{userTier?.pointPending}</span>
                &nbsp; bạn vui lòng liên hệ CSKH
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={onConfirm}
                >
                  Xác nhận
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;
