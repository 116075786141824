import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GiWallet } from "react-icons/gi";

import "./account.css";
import { apis } from "../../apis";

const Account = () => {
  const [profile, setProfile] = useState(null);
  const [tier, setTier] = useState(null);
  const [config, setConfig] = useState(null);

  const onLogout = () => {
    try {
      apis.logout().then(() => {
        localStorage.removeItem("t");
        window.location.href = "/auth/login";
      });
    } catch (error) {
      localStorage.removeItem("t");
      window.location.href = "/auth/login";
    }
  };

  const getConfig = () => {
    apis.getCopnfig().then((response) => {
      const teleConfig = response.data?.find((item) => item.key === "TELEGRAM");
      setConfig(teleConfig);
    });
  };

  const getProfile = () => {
    apis.getProfile().then((resp) => {
      setProfile(resp.data);
    });
  };
  const getTier = () => {
    apis.getUserTier().then((resp) => {
      setTier(resp.data);
    });
  };

  useEffect(() => {
    getProfile();
    getTier();
    getConfig();
  }, []);

  return (
    <div className="wallet-page page-container">
      <div className="coin-overview">
        <div>
          <div className="total">{profile?.username || "Đang tải..."}</div>
          <div className="description">{tier?.tier?.name}</div>
        </div>
      </div>

      <div className="wallet-navigation">
        <div className="card">
          <div className="card-header">
            <h6>Thiết Lập Cá Nhân</h6>
          </div>
          <div className="card-body">
            <div>
              <Link to="/account/mission-history" className="wallet-item">
                <div className="wallet-item-left">
                  <GiWallet />
                </div>
                <div>
                  <div className="title">Hồ sơ đặt hàng</div>
                  <div>Xem lịch sử đặt hàng</div>
                </div>
              </Link>
              <Link to="/notification" className="wallet-item">
                <div className="wallet-item-left">
                  <GiWallet />
                </div>
                <div>
                  <div className="title">Thông báo tin nhắn</div>
                  <div>Xem thông báo tin nhắn</div>
                </div>
              </Link>
              <Link to="/account/bank" className="wallet-item">
                <div className="wallet-item-left">
                  <GiWallet />
                </div>
                <div>
                  <div className="title">Tài khoản ngân hàng</div>
                  <div>Sửa thông tin tài khoản</div>
                </div>
              </Link>
              <Link to="/account/address" className="wallet-item">
                <div className="wallet-item-left">
                  <GiWallet />
                </div>
                <div>
                  <div className="title">Địa chỉ nhận hàng</div>
                  <div>Sửa thông tin nhận hàng</div>
                </div>
              </Link>
              {config && (
                <a
                  href={config.value}
                  target="_blank"
                  className="wallet-item"
                  rel="noreferrer"
                >
                  <div className="wallet-item-left">
                    <GiWallet />
                  </div>
                  <div>
                    <div className="title">Liên hệ CSKH</div>
                    <div>Chăm sóc khách hàng</div>
                  </div>
                </a>
              )}
              <div onClick={onLogout} className="wallet-item">
                <div className="wallet-item-left">
                  <GiWallet />
                </div>
                <div>
                  <div className="title">Đăng xuất</div>
                  <div>Thoát khỏi hệ thống</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
