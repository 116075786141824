import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";

import "./auth.css";
import { apis } from "../../apis";
import { setAuthToken } from "../../services/httpService";

const Login = () => {
  const [form, setForm] = useState({
    username: "",
    password: "",
  });
  const navigate = useNavigate();

  const onInputChange = (e) => {
    let value = e.target.value;

    if (e.target.name === "username") {
      value = value.replace(/\D/g, "");
    }

    setForm({
      ...form,
      [e.target.name]: value,
    });
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      let isErr = false;

      for (const key of ["username", "password"]) {
        if (!form[key]) {
          isErr = true;
          break;
        }
      }
      if (isErr) {
        toast.error("Vui lòng nhập đầy đủ thông tin");
        return;
      }

      const resp = await apis.login(form);
      setAuthToken(resp.data.accessToken);

      toast.success("Đăng nhập thành công");
      navigate("/");
    } catch (error) {
      toast.error("Đăng nhập thất bại");
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="navigation-area">
        <Link to="/auth/register">Tạo tài khoản</Link>
      </div>
      <form className="auth-content" onSubmit={onSubmit}>
        <div className="d-flex justify-content-center">
          <div className="logo-area">
            <img className="logo" src="/logo.png" alt="SaigonCentre" />
            <h1 className="title">SAIGONCENTRE</h1>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="username">Số điện thoại</label>
          <input
            type="text"
            name="username"
            className="form-control"
            placeholder="Nhập số điện thoại"
            value={form.username}
            onChange={onInputChange}
            autoFocus
            autoComplete="off"
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Mật khẩu</label>
          <input
            type="password"
            name="password"
            className="form-control"
            placeholder="Nhập mật khẩu"
            value={form.password}
            onChange={onInputChange}
            autoComplete="off"
          />
        </div>
        {/* <div className="description">
          <a href="/auth/register">Quên mật khẩu?</a>
        </div> */}
        <button className="btn btn-primary btn-block">Đăng nhập</button>
      </form>
    </div>
  );
};

export default Login;
