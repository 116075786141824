import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";

import "./styles/reset.css";
import "./styles/app.css";
import { setAuthToken } from "./services/httpService";

function App({ children }) {
  const [isAuth, setIsAuth] = useState(false);
  const [isInAuthPage, setIsInAuthPage] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("t");
    const isInAuthPage = window.location.pathname.includes("/auth");

    if (token) {
      setIsAuth(true);
      setAuthToken(token);
    }
    if (isInAuthPage) {
      setIsInAuthPage(true);
    }

    if (!token && !isInAuthPage) {
      window.location.href = "/auth/login";
    }

    // if (token && isInAuthPage) {
    //   window.location.href = "/";
    // }
  }, []);

  function findGetParameter(parameterName) {
    var result = null,
      tmp = [];
    window.location.search
      .substr(1)
      .split("&")
      .forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      });
    return result;
  }

  useEffect(() => {
    const ref = findGetParameter("ref");
    if (ref) {
      localStorage.setItem("ref", ref);
    }
  }, []);

  if (isInAuthPage) {
    return (
      <>
        <div className="App">
          {children}
          <Toaster />
        </div>
      </>
    );
  }

  return (
    <div className="App">
      {isAuth && children}
      <Toaster />
    </div>
  );
}

export default App;
