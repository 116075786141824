import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { SwiperSlide, Swiper } from "swiper/react";
import SwiperCore from "swiper";
import { FaArrowRight } from "react-icons/fa";
import { FaPiggyBank } from "react-icons/fa";
import { FaLock } from "react-icons/fa";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./home.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { apis } from "../../apis/";
SwiperCore.use([Autoplay]);

const Home = () => {
  const [widgets, setWidgets] = useState([]);
  const [sliderImages, setSliderImages] = useState([]);

  const getMission = async () => {
    const resp = await apis.getWidgets();
    setWidgets(resp.data.filter((widget) => widget.code === "HOME"));

    const banners = resp.data
      .filter((widget) => widget.code === "BANNER")
      .map(
        (widget) => window.baseUrlSocket + "/photos/" + widget.image?.fileName
      );

    setSliderImages(
      banners.length
        ? banners
        : ["/slider1.png", "/slider2.png", "/slider3.png"]
    );
  };

  useEffect(() => {
    getMission();
  }, []);

  return (
    <div className="home-page page-container">
      <div className="slider">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={20}
          slidesPerView={1}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          autoplay={{ delay: 2000 }}
          loop={true}
        >
          {sliderImages.map((image, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="slider-item">
                  <img src={image} alt="slider" />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>

      <div className="action">
        <div className="card">
          <div className="card-body">
            <Link className="deposit" to="/account/deposit">
              <div className="deposit-left">
                <div className="icon">
                  <FaPiggyBank />
                </div>
                <div>
                  <div className="title">Nạp tiền</div>
                  <div>Bắt đầu hành trình kiếm tiền của bạn ngay bây giờ</div>
                </div>
              </div>
              <div className="deposit-right">
                <FaArrowRight />
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className="case">
        <div className="card">
          <div className="card-body">
            <div>
              <div className="row">
                {widgets.map((widget, index) => {
                  return (
                    <div
                      className="col col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4"
                      key={index}
                    >
                      <div className="card">
                        <img
                          className="card-img-top"
                          src={
                            window.baseUrlSocket +
                            "/photos/" +
                            widget.image?.fileName
                          }
                          alt={widget.name}
                        />
                        <div className="card-body">
                          <div className="case-item">
                            <div>
                              {index === 0 && (
                                <button className="btn btn-primary btn-sm">
                                  Mở khóa
                                </button>
                              )}
                              {index !== 0 && (
                                <button className="btn btn-sm btn-lock">
                                  <FaLock />
                                </button>
                              )}
                            </div>
                            <div className="title">{widget.name}</div>
                            <div className="description">
                              {widget.description}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
