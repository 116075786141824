import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";

import "./auth.css";
import { apis } from "../../apis";

const Register = () => {
  const [form, setForm] = useState({
    username: "",
    password: "",
    name: "",
    confirmPassword: "",
    refreferralCode: localStorage.getItem("ref"),
  });

  const navigate = useNavigate();

  const onInputChange = (e) => {
    let value = e.target.value;

    if (e.target.name === "username") {
      value = value.replace(/\D/g, "");
    }

    if (e.target.name === "name") {
      value = value.replace(/[^a-zA-Z\s]/g, "").toUpperCase();
    }

    setForm({
      ...form,
      [e.target.name]: value,
    });
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      let isErr = false;

      if (!form.refreferralCode) {
        toast.error("Đăng ký thất bại");
        return;
      }

      for (const key of ["username", "password", "name", "confirmPassword"]) {
        if (!form[key]) {
          isErr = true;
          break;
        }
      }

      if (isErr) {
        toast.error("Vui lòng nhập đầy đủ thông tin");
        return;
      }

      if (form.password.length < 6) {
        toast.error("Mật khẩu phải lớn hơn 6 ký tự");
        return;
      }

      if (form.confirmPassword !== form.password) {
        toast.error("Mật khẩu không trùng khớp");
        return;
      }

      await apis.register({
        ...form,
      });
      toast.success("Đăng ký thành công");
      navigate("/auth/login");
    } catch (error) {
      toast.error("Đã có lỗi xảy ra");
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="navigation-area">
        <Link to="/auth/login">Đã Có Tài Khoản, Đăng Nhập Ngay</Link>
      </div>
      <form className="auth-content" onSubmit={onSubmit}>
        <div className="d-flex justify-content-center">
          <div className="logo-area">
            <img className="logo" src="/logo.png" alt="SaigonCentre" />
            <h1 className="title">SAIGONCENTRE</h1>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="username">Số điện thoại</label>
          <input
            type="text"
            name="username"
            className="form-control"
            placeholder="Nhập số điện thoại"
            value={form.username}
            onChange={onInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="username">Tên</label>
          <input
            type="text"
            name="name"
            className="form-control"
            placeholder="Nhập tên"
            value={form.name}
            onChange={onInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Mật khẩu</label>
          <input
            type="password"
            name="password"
            className="form-control"
            placeholder="Nhập mật khẩu"
            value={form.password}
            onChange={onInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Nhập lại mật khẩu</label>
          <input
            type="password"
            name="confirmPassword"
            className="form-control"
            placeholder="Xác nhận mật khẩu"
            value={form.confirmPassword}
            onChange={onInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Mã mời</label>
          <input
            type="text"
            name="refreferralCode"
            className="form-control"
            placeholder="Vui lòng nhập mã mời"
            value={form.refreferralCode}
            onChange={onInputChange}
          />
        </div>
        <button className="btn btn-primary btn-block">Đăng ký</button>
      </form>
    </div>
  );
};

export default Register;
