import React, { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import "./style.css";
import { apis } from "../../../apis";

const Index = () => {
  const [adminBanks, setAdminBanks] = useState([]);
  const [currentBanks, setCurrentBanks] = useState(null);
  const [randomCode, setRandomCode] = useState("");
  const [amount, setAmount] = useState(500);
  const navigate = useNavigate();

  const onBack = () => {
    navigate("/wallet");
  };

  const getBanks = async () => {
    const banks = await apis.getAdminBanks();
    setAdminBanks(banks.data);
  };

  const getRandomCode = async () => {
    const data = await apis.getRandomCode();
    setRandomCode(data.data);
  };

  const onChangeAdminBank = (e) => {
    setCurrentBanks(adminBanks.find((bank) => bank.bank.id === e.target.value));
    getRandomCode();
  };

  const onConfirm = async () => {
    try {
      const resp = await apis.submitDeposit({
        bankId: currentBanks.id,
        amount: amount,
        transactionCode: randomCode,
      });
      if (resp.data) {
        toast.success("Gửi yêu cầu nạp tiền thành công, vui lòng chờ ít phút");
        onBack();
      }
    } catch (error) {
      toast.error(
        "Yêu cầu của bạn không thành công vui lòng liên hệ CSKH để được hỗ trợ"
      );
    }
  };

  useEffect(() => {
    getBanks();
  }, []);

  return (
    <div className="sub-page page-container">
      <div className="sub-page-header">
        <button className="btn btn-light" onClick={onBack}>
          <FaArrowLeftLong /> Trở lại
        </button>
        <div className="text-bold">Rút tiền</div>
      </div>

      <div className="sub-page-content">
        <h6>Vui lòng lựa chọn ngân hàng</h6>
        <select className="form-control bank-list" onChange={onChangeAdminBank}>
          <option value="" hidden></option>
          {adminBanks.map((bank) => (
            <option value={bank.bank.id}>{bank.bank.name}</option>
          ))}
        </select>
        {currentBanks && (
          <div className="bank-info-wrapper">
            <h6>Thông tin chuyển khoản</h6>
            <div className="card">
              <div className="card-body">
                <div className="bank-info-item">
                  <div className="title">Tên chủ tài khoản</div>
                  <div className="text-bold">{currentBanks.name}</div>
                </div>
                <div className="bank-info-item">
                  <div className="title">Số tài khoản</div>
                  <div className="text-bold">{currentBanks.bankNumber}</div>
                </div>
                <div className="bank-info-item">
                  <div className="title">Tên ngân hàng</div>
                  <div className="text-bold">{currentBanks.bank.name}</div>
                </div>
                {/* <div className="bank-info-item">
                  <div className="title">Nội dung chuyển khoản</div>
                  <div className="text-bold text-success">{randomCode}</div>
                </div> */}
                {/* <div className="bank-info-item">
                  <div className="title">Số tiền chuyển</div>
                  <div className="text-bold form-amount">
                    <input
                      className="form-control"
                      type="number"
                      onChange={(e) => setAmount(e.target.value)}
                      value={amount}
                      required
                    />{" "}
                    K
                  </div>
                </div>

                <div className="form-bottom">
                  <button className="btn btn-primary" onClick={onConfirm}>
                    Xác nhận đã thanh toán
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
