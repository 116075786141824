import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaWallet } from "react-icons/fa";
import { GiWallet } from "react-icons/gi";
import { apis } from "../../apis";

import "./wallet.css";

const Wallet = () => {
  const [balance, setBalance] = useState(0);

  const getBalance = async () => {
    const resp = await apis.getBalance();
    setBalance(resp.data?.balance);
  };

  useEffect(() => {
    getBalance();
  }, []);

  return (
    <div className="wallet-page page-container">
      <div className="coin-overview">
        <div>
          <div className="total">{balance}</div>
          <div className="description">Tổng tài sản</div>
        </div>
      </div>

      <div className="wallet-income">
        <div className="card">
          <div className="card-body">
            <div className="income-overview">
              <div className="income-overview-item">
                <div>
                  <div className="title">{balance}</div>
                  <div>Thu Nhập Hoa Hồng</div>
                </div>
              </div>
              <div className="right">
                <FaWallet />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="wallet-navigation">
        <div className="card">
          <div className="card-header">
            <h6>Thông tin chi tiết quỹ</h6>
          </div>
          <div className="card-body">
            <div>
              <Link to="/account/deposit" className="wallet-item">
                <div className="wallet-item-left">
                  <GiWallet />
                </div>
                <div>
                  <div className="title">Nạp Tiền</div>
                  <div>Nạp lại số dư </div>
                </div>
              </Link>
              <Link to="/account/deposit-history" className="wallet-item">
                <div className="wallet-item-left">
                  <GiWallet />
                </div>
                <div>
                  <div className="title">Hồ sơ nạp tiền</div>
                  <div>Xem lịch sử nạp tiền</div>
                </div>
              </Link>
              <Link to="/account/withdraw" className="wallet-item">
                <div className="wallet-item-left">
                  <GiWallet />
                </div>
                <div>
                  <div className="title">Rút Tiền</div>
                  <div>Rút tiền về tài khoản ngân hàng của bạn</div>
                </div>
              </Link>
              <Link to="/account/withdraw-history" className="wallet-item">
                <div className="wallet-item-left">
                  <GiWallet />
                </div>
                <div>
                  <div className="title">Lịch sử rút tiền</div>
                  <div>Xem lịch sử rút tiền</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
