import { Header } from "./components/Header";
import { NavigationBar } from "./components/NavigationBar";

import "./default-layout.css";

export const DefaultLayout = ({ children }) => {
  return (
    <div className="default-layout">
      <Header />
      <main>{children}</main>
      <NavigationBar />
    </div>
  );
};
