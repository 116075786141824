import axios from "axios";

const axiosIns = axios.create({
  baseURL: window.baseUrlSocket,
});

axiosIns.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      localStorage.removeItem("t");

      window.location.href = "/auth/login";
    }
    return Promise.reject(error);
  }
);

export const setAuthToken = (token) => {
  localStorage.setItem("t", token);
  axiosIns.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const httpService = axiosIns;
