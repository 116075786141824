import React, { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import "./style.css";
import { apis } from "../../../apis";

const Index = () => {
  const [profile, setProfile] = useState(null);

  const onLogout = () => {
    apis.logout().then(() => {
      localStorage.removeItem("t");
      window.location.href = "/auth/login";
    });
  };

  const getProfile = () => {
    apis.getProfile().then((resp) => {
      setProfile(resp.data);
    });
  };

  const navigate = useNavigate();

  const onBack = () => {
    navigate("/account");
  };

  const onFieldChange = (e) => {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value,
    });
  };

  const onConfirm = async (e) => {
    e.preventDefault();
    try {
      const resp = await apis.updateProfile({
        ...profile,
      });
      if (resp.data) {
        toast.success("Lưu địa chỉ thành công");
      }
    } catch (error) {
      toast.error("Lưu địa chỉ thất bại");
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <div className="sub-page page-container">
      <div className="sub-page-header">
        <button className="btn btn-light" onClick={onBack}>
          <FaArrowLeftLong /> Trở lại
        </button>
        <div className="text-bold">Sửa Thông Tin Địa Chỉ</div>
      </div>

      <div className="sub-page-content">
        <form onSubmit={onConfirm}>
          <div className="form-group">
            <label>Người nhận hàng</label>
            <input
              className="form-control"
              required
              placeholder="Nhập họ và tên người nhận hàng"
              value={profile?.addressName}
              onChange={onFieldChange}
              name="addressName"
            />
          </div>
          <div className="form-group">
            <label>Số điện thoại</label>
            <input
              className="form-control"
              required
              type="number"
              placeholder="Nhập số điện thoại"
              value={profile?.addressPhone}
              onChange={onFieldChange}
              name="addressPhone"
            />
          </div>
          <div className="form-group">
            <label>Chi tiết địa chỉ</label>
            <input
              className="form-control"
              required
              placeholder="Nhập địa chỉ nhận hàng"
              value={profile?.addressDetail}
              onChange={onFieldChange}
              name="addressDetail"
            />
          </div>
          <div className="form-group">
            <label>Quốc gia / Thành phố</label>
            <input
              className="form-control"
              type="text"
              required
              placeholder="Nhập quốc gia thành phố"
              value={profile?.addressCountry}
              onChange={onFieldChange}
              name="addressCountry"
            />
          </div>
          <div className="form-bottom">
            <button className="btn btn-primary">Lưu lại</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Index;
