import React, { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import "./style.css";
import { apis } from "../../../apis";

const Index = () => {
  const [bank, setBank] = useState(null);
  const navigate = useNavigate();

  const onBack = () => {
    navigate("/wallet");
  };

  const getBank = async () => {
    const banks = await apis.myBank();
    if (banks.data) {
      setBank(banks.data);
    } else {
      navigate("/account/bank");
    }
  };

  const [form, setForm] = useState({
    amount: 0,
  });

  const onFieldChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onConfirm = async (e) => {
    e.preventDefault();
    try {
      const resp = await apis.withdraw({
        ...form,
        cashPassword: "",
      });
      if (resp.data) {
        toast.success("Tạo yêu cầu thành công");
      }
    } catch (error) {
      toast.error("Số dư bạn không đủ");
    }
  };

  useEffect(() => {
    getBank();
  }, []);

  return (
    <div className="sub-page page-container">
      <div className="sub-page-header">
        <button className="btn btn-light" onClick={onBack}>
          <FaArrowLeftLong /> Trở lại
        </button>
        <div className="text-bold">Rút tiền</div>
      </div>

      <div className="sub-page-content">
        {bank && (
          <form onSubmit={onConfirm}>
            <div className="form-group">
              <label>Tên ngân hàng</label>
              <input
                className="form-control"
                required
                placeholder="Nhập họ và tên thật của bạn"
                value={bank.bankName}
                onChange={onFieldChange}
                name="name"
                disabled
              />
            </div>
            <div className="form-group">
              <label>Họ và tên</label>
              <input
                className="form-control"
                required
                placeholder="Nhập họ và tên thật của bạn"
                value={bank.name}
                onChange={onFieldChange}
                name="name"
                disabled
              />
            </div>
            <div className="form-group">
              <label>Số tài khoản</label>
              <input
                className="form-control"
                type="number"
                required
                placeholder="Nhập số tài khoản ngân hàng"
                value={bank.bankNumber}
                onChange={onFieldChange}
                name="bankNumber"
                disabled
              />
            </div>
            <div className="form-group">
              <label>Số tiền cần rút</label>
              <input
                className="form-control"
                type="number"
                required
                placeholder="Số tiền cần rút"
                value={form.amount}
                onChange={onFieldChange}
                name="amount"
              />
            </div>
            <div className="form-bottom">
              <button className="btn btn-primary">Xác nhận rút tiền</button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Index;
