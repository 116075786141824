import { FaUser } from "react-icons/fa6";
import { IoNotifications } from "react-icons/io5";
import { Link } from "react-router-dom";
export const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <Link to="/">
          <h1>TTTM Saigon Centre</h1>
        </Link>
      </div>
      <div>
        <div className="auth">
          <Link to="/notification">
            <IoNotifications />
          </Link>
          <Link to="/account">
            <FaUser />
          </Link>
        </div>
      </div>
    </header>
  );
};
