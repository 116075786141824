import { httpService } from "../services/httpService";

export const apis = {
  login: (payload) => {
    return httpService.post("/v1/auth/sign-in", payload);
  },
  logout: () => {
    return httpService.get("/v1/auth/sign-out");
  },
  getCopnfig: () => {
    return httpService.get("/v1/configurations");
  },
  getProfile: () => {
    return httpService.get("/v1/users/me");
  },
  register: (payload) => {
    return httpService.post("/v1/auth/sign-up", payload);
  },
  getAdminBanks: () => {
    return httpService.get("/v1/payments/admin-banks");
  },
  getBanks: () => {
    return httpService.get("/v1/payments/banks");
  },
  myBank: () => {
    return httpService.get("/v1/payments/user/my-bank");
  },
  setUserBank: (data) => {
    return httpService.post("/v1/payments/user-bank", data);
  },
  setUserBank: (data) => {
    return httpService.post("/v1/payments/user-bank", data);
  },
  bankHistory: (type) => {
    return httpService.get("/v1/payments/my-bank-histories", {
      params: {
        type,
        page: 1,
        take: 10,
      },
    });
  },
  getBalance: () => {
    return httpService.get("/v1/users/balance");
  },
  getRandomCode: () => {
    return httpService.get("/v1/payments/random-code");
  },
  submitDeposit: (data) => {
    return httpService.post("/v1/payments/transaction", data);
  },
  withdraw: (data) => {
    return httpService.post("/v1/payments/transaction/with-draw", data);
  },
  getUserMission: () => {
    return httpService.get("/v1/missions/user");
  },
  getUserTier: () => {
    return httpService.get("/v1/missions/tier");
  },
  performMission: (id) => {
    return httpService.post(`/v1/missions/${id}/perform`);
  },
  missionHistory: () => {
    return httpService.get(`/v1/missions/history`);
  },
  updateProfile: (data) => {
    return httpService.patch(`/v1/users`, data);
  },
  getNotification: () => {
    return httpService.get(`/v1/notifications/list`);
  },
  getWidgets: () => {
    return httpService.get(`/v1/widgets`);
  },
};
