export const transactionStatus = (status) => {
  switch (status) {
    case 0:
      return "Chờ xử lý";
    case 1:
      return "Thành công";
    case 2:
      return "Thất bại";
  }
};
