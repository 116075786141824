import { Link, useLocation } from "react-router-dom";

import { FaBagShopping } from "react-icons/fa6";
import { FaWallet } from "react-icons/fa6";
import { FaUser } from "react-icons/fa6";
import { FaHouse } from "react-icons/fa6";
import { useMemo } from "react";
import classNames from "classnames";

export const NavigationBar = () => {
  const location = useLocation();

  const links = useMemo(() => {
    return [
      {
        path: "/",
        label: "Trang chủ",
        icon: FaHouse,
        isActive: location.pathname === "/",
      },
      {
        path: "/missions",
        label: "Gửi đơn",
        icon: FaBagShopping,
        isActive: location.pathname === "/missions",
      },
      {
        path: "/wallet",
        label: "Ví tiền",
        icon: FaWallet,
        isActive: location.pathname === "/wallet",
      },
      {
        path: "/account",
        label: "Tài khoản",
        icon: FaUser,
        isActive: location.pathname === "/account",
      },
    ];
  }, [location]);

  return (
    <nav className="navigation-bar">
      <ul>
        {links.map((link) => (
          <li
            key={link.path}
            className={classNames({
              active: link.isActive,
            })}
          >
            <Link to={link.path}>
              <link.icon />
              <span>{link.label}</span>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};
