import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { MdPlayArrow } from "react-icons/md";
import RoulettePro from "react-roulette-pro";

import { apis } from "../../apis";

import "react-roulette-pro/dist/index.css";

import "./missions.css";
const getRandomIntInRange = (min = 0, max = 0) => {
  const minNumber = Math.ceil(min);
  const maxNumber = Math.floor(max);

  return Math.floor(Math.random() * (maxNumber - minNumber + 1) + minNumber);
};

const MissionLive = () => {
  const [missions, setMissions] = useState([]);
  const [currentTier, setCurrentTier] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [balance, setBalance] = useState(0);
  const [isPending, setIsPending] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [currentMission, setCurrentMission] = useState(null);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isRequesting, setIsRequesting] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const getCountReproduce = (length) => {
    if (length <= 20) {
      return 3;
    }

    if (length > 20 && length < 40) {
      return 2;
    }

    return 2;
  };

  const missionReproduction = useMemo(() => {
    const mapped = missions.map((item, idx) => ({
      image: window.baseUrlSocket + "/photos/" + item.image?.fileName,
      id: item.id,
      realIndex: idx,
    }));

    const repeatCount = getCountReproduce(missions.length);

    if (repeatCount === 1) {
      return [...mapped.slice(0, missions.length / 2), ...mapped];
    }

    return Array.from({ length: repeatCount }).reduce(
      (acc) => [...acc, ...mapped],
      []
    );
  }, [missions]);

  const getBalance = async () => {
    const resp = await apis.getBalance();
    setBalance(resp.data);
  };

  const onMissionClick = async () => {
    if (missions.length === 0) {
      toast("Không có nhiệm vụ nào, vui lòng thử lại sau!");
      return;
    }
    if (isPending || isLoading) return;

    const tier = await apis.getUserTier();
    setCurrentTier(tier.data);

    if (tier.data?.isPending) {
      setIsPending(true);
      return;
    }

    if (tier.data?.isCompleted) {
      setIsCompleted(true);
      return;
    }

    setSpinning(true);
    const repeatCount = getCountReproduce(missions.length);

    // const missionsNotCompleted = missions.filter((item) => !item.isCompleted);

    // const randomPrizeIndex = getRandomIntInRange(
    //   0,
    //   missionsNotCompleted.length - 1
    // );
    // let item = missionsNotCompleted[randomPrizeIndex];
    // if (item.isCompleted) {
    //   const randomPrizeIndex2 = getRandomIntInRange(
    //     0,
    //     missionsNotCompleted.length - 1
    //   );
    //   item = missionsNotCompleted[randomPrizeIndex2];
    // }

    let targetIndex = currentTier.orderMission;
    const randomPrizeIndexOffset = missions.length * (repeatCount - 1);

    let index = 0;
    if (missionReproduction[index]?.isCompleted) {
      index = missionReproduction.findIndex((i) => !i.isCompleted);
    } else {
      index =
        repeatCount > 1
          ? targetIndex + randomPrizeIndexOffset - 1
          : missions.length - 1 - targetIndex + missions.length / 2;
    }

    if (index < 0) {
      return window.location.reload();
    }

    if (isFirstTime) {
      setIsFirstTime(false);
    }

    setCurrentIndex(index);
  };

  const getUserMission = async () => {
    const resp = await apis.getUserMission();
    if (resp.data) {
      setCurrentTier(resp.data?.userTier);
      setMissions(resp.data?.missions);

      if (resp.data?.userTier?.isPending) {
        setIsPending(true);
      }

      if (resp.data?.userTier?.isCompleted) {
        setIsCompleted(true);
      }
    }
  };

  const onMissionComplete = async () => {
    if (isRequesting) {
      return;
    }
    if (currentMission) {
      try {
        setCurrentMission(null);
        setIsRequesting(true);

        await apis.performMission(currentMission.id);
        setMissions(
          missions.map((_, i) => {
            if (i === currentIndex) {
              return {
                ..._,
                isCompleted: true,
              };
            }
            return _;
          })
        );

        await apis.getUserTier().then((r) => {
          setCurrentTier(r.data);
          if (r.data?.userTier?.isCompleted) {
            setIsCompleted(true);
          }
        });
        toast.success("Gửi đơn thành công!");
        await getBalance();
      } finally {
        setIsLoading(false);
        setIsRequesting(false);
      }
    }
  };

  const onCloseModal = () => {
    setIsLoading(false);
    setCurrentMission(null);
  };

  useEffect(() => {
    getUserMission();
    getBalance();
  }, []);

  const handlePrizeDefined = () => {
    setSpinning(false);
    setCurrentMission(missions[missionReproduction[currentIndex].realIndex]);
  };

  return (
    <div className="mission-page page-container">
      <div className="mission-top">
        <div className="card">
          <div className="card-body">
            <div className="mission-overview">
              <div className="mission-overview-item">
                <div>
                  <div className="title">
                    {(balance?.balance || 0).toFixed(2)}
                  </div>
                  <div>Tổng tài sản</div>
                </div>
              </div>
              <div className="mission-overview-item">
                <div>
                  <div className="title">
                    {(balance?.commission || 0).toFixed(2)}
                  </div>
                  <div>Hoa hồng hôm nay</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mission">
        <div className="card">
          <div className="card-body">
            <div className="mission-wrapper">
              <div className="mission-sliders">
                <div className="mission-slider">
                  <RoulettePro
                    prizes={missionReproduction.map((item, idx) => ({
                      ...item,
                      id: item.id + "xxxxx" + idx,
                    }))}
                    prizeIndex={currentIndex}
                    options={{
                      stopInCenter: true,
                      withoutAnimation: true,
                    }}
                    classes={{
                      wrapper: "roulette-pro-wrapper-additional-styles",
                    }}
                    start={spinning}
                    type="vertical"
                    spinningTime={8}
                    soundWhileSpinning={false}
                    spinning={spinning}
                    onPrizeDefined={handlePrizeDefined}
                    defaultDesignOptions={{ hideCenterDelimiter: true }}
                  />
                </div>
                <div className="mission-slider">
                  <RoulettePro
                    prizes={missionReproduction.map((item, idx) => ({
                      ...item,
                      id: item.id + "32xxxx" + idx,
                    }))}
                    prizeIndex={currentIndex}
                    options={{
                      stopInCenter: true,
                      withoutAnimation: true,
                    }}
                    classes={{
                      wrapper: "roulette-pro-wrapper-additional-styles",
                    }}
                    start={spinning}
                    type="vertical"
                    spinningTime={8}
                    soundWhileSpinning={false}
                    spinning={spinning}
                    defaultDesignOptions={{ hideCenterDelimiter: true }}
                  />
                </div>
                <div className="mission-slider">
                  <RoulettePro
                    prizes={missionReproduction.map((item, idx) => ({
                      ...item,
                      id: item.id + "3xxxx" + idx,
                    }))}
                    prizeIndex={currentIndex}
                    options={{
                      stopInCenter: true,
                      withoutAnimation: true,
                    }}
                    classes={{
                      wrapper: "roulette-pro-wrapper-additional-styles",
                    }}
                    start={spinning}
                    type="vertical"
                    spinningTime={8}
                    soundWhileSpinning={false}
                    spinning={spinning}
                    defaultDesignOptions={{ hideCenterDelimiter: true }}
                  />
                </div>
              </div>
              <div className="pointer">
                <MdPlayArrow className="arrow left" />
                <MdPlayArrow className="arrow right" />
              </div>
              {isPending && (
                <div className="blocked">
                  <div className="text-success">
                    Chúc mừng bạn đã nhận được đơn hàng may mắn. Số điểm cần quy
                    đổi là{" "}
                    <span className="text-danger">
                      {currentTier.pointPending}
                    </span>
                    &nbsp; bạn vui lòng liên hệ CSKH.
                  </div>
                </div>
              )}
              {isCompleted && (
                <div className="blocked">
                  <div className="text-success">
                    Bạn đã hoàn thành tất cả các đơn hàng!
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mission-action">
        <div className="card">
          <div className="card-body">
            <div className="mission-action-body">
              <button
                className="btn btn-primary w-100"
                onClick={onMissionClick}
                disabled={!currentTier || isPending || isLoading || isCompleted}
              >
                Kết hợp gửi đơn
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mission-bottom">
        <div className="card">
          <div className="card-body">
            {currentTier && (
              <div>
                <div>
                  Tỷ lệ hoa hồng:{" "}
                  <span className="text-success">
                    {currentTier?.tier?.commissionRate}%
                  </span>
                </div>
                <div>
                  Đơn hàng hôm nay:{" "}
                  <span className="text-success">
                    {currentTier?.orderMission}/{currentTier?.totalMission}
                  </span>
                </div>
              </div>
            )}
            {!currentTier && <div>Bạn chưa thể gửi đơn lúc này</div>}
          </div>
        </div>
      </div>

      {currentMission && (
        <div
          className={classNames({
            modal: true,
            active: !!currentMission,
          })}
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{currentMission.name}</h5>
              </div>
              <div className="modal-body">
                <div className="mission-image-pop-up">
                  <img
                    width="100%"
                    src={
                      window.baseUrlSocket +
                      "/photos/" +
                      currentMission.image?.fileName
                    }
                    alt={currentMission.name}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={onMissionComplete}
                >
                  Xác nhận
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={onCloseModal}
                >
                  Huỷ
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MissionLive;
