import React, { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

import "./style.css";
import { apis } from "../../../apis";
import { transactionStatus } from "../../../helpers";
import moment from "moment";

const Index = () => {
  const [history, setHistory] = useState([]);
  const navigate = useNavigate();

  const onBack = () => {
    navigate("/account");
  };

  const getNotification = async () => {
    const resp = await apis.getNotification();
    setHistory(resp.data);
  };

  useEffect(() => {
    getNotification();
  }, []);

  return (
    <div className="sub-page page-container">
      <div className="sub-page-header">
        <button className="btn btn-light" onClick={onBack}>
          <FaArrowLeftLong /> Trở lại
        </button>
        <div className="text-bold">Thông báo tin nhắn</div>
      </div>

      <div className="sub-page-content">
        {!!history.length && (
          <div>
            {history.map((item) => (
              <div key={item.id} className="card mt-2">
                <div className="card-body">
                  <div className="text-bold">{item.notification.title}</div>
                  <div className="text-small">
                    {moment(item.createdAt).format("YYYY-MM-DD hh:mm:ss")}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.notification.content,
                    }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        )}
        {!history.length && (
          <div className="text-center">Không có tin nhắn nào</div>
        )}
      </div>
    </div>
  );
};

export default Index;
