import React, { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

import "./style.css";
import { apis } from "../../../apis";
import { transactionStatus } from "../../../helpers";

const Index = () => {
  const [history, setHistory] = useState([]);
  const navigate = useNavigate();

  const onBack = () => {
    navigate("/wallet");
  };

  const getHistory = async () => {
    const banks = await apis.bankHistory("INCOME");
    setHistory(banks.data.metadata);
  };

  useEffect(() => {
    getHistory();
  }, []);

  return (
    <div className="sub-page page-container">
      <div className="sub-page-header">
        <button className="btn btn-light" onClick={onBack}>
          <FaArrowLeftLong /> Trở lại
        </button>
        <div className="text-bold">Lịch sử nạp tiền</div>
      </div>

      <div className="sub-page-content">
        {!!history.length && (
          <div>
            {history.map((item) => (
              <div key={item.id} className="card mt-2">
                <div className="card-body">
                  <div className="text-bold">
                    {transactionStatus(item.status)}
                  </div>
                  <div>{item.createdAt}</div>
                  <div>
                    Số điểm: <span className="text-bold">{item.amount}</span>
                  </div>
                  <div>
                    Mã giao dịch:{" "}
                    <span className="text-bold">{item.transactionCode}</span>
                  </div>
                  <div>Thời gian: {item.createdAt}</div>
                </div>
              </div>
            ))}
          </div>
        )}
        {!history.length && (
          <div className="text-center">Không có lịch sử nạp tiền</div>
        )}
      </div>
    </div>
  );
};

export default Index;
