import React from "react";
import App from "./App";

import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import { DefaultLayout } from "./components/layouts/default-layout";

import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Home from "./pages/home";
import Missions from "./pages/missions/mission-v2";
import Wallet from "./pages/wallet";
import Account from "./pages/account";
import Deposit from "./pages/sub-page/deposit";
import Withdraw from "./pages/sub-page/withdraw";
import Bank from "./pages/sub-page/bank";
import WithdrawHistory from "./pages/sub-page/withdraw-history";
import DepositHistory from "./pages/sub-page/deposit-history";
import MissionHistory from "./pages/sub-page/mission-history";
import Notification from "./pages/sub-page/notification";
import Address from "./pages/sub-page/address";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <DefaultLayout>
        <Home />
      </DefaultLayout>
    ),
  },
  {
    path: "/missions",
    element: (
      <DefaultLayout>
        <Missions />
      </DefaultLayout>
    ),
  },
  {
    path: "/wallet",
    element: (
      <DefaultLayout>
        <Wallet />
      </DefaultLayout>
    ),
  },
  {
    path: "/account",
    element: (
      <DefaultLayout>
        <Account />
      </DefaultLayout>
    ),
  },
  {
    path: "/account/address",
    element: (
      <DefaultLayout>
        <Address />
      </DefaultLayout>
    ),
  },
  {
    path: "/account/deposit",
    element: (
      <DefaultLayout>
        <Deposit />
      </DefaultLayout>
    ),
  },
  {
    path: "/account/withdraw",
    element: (
      <DefaultLayout>
        <Withdraw />
      </DefaultLayout>
    ),
  },
  {
    path: "/account/bank",
    element: (
      <DefaultLayout>
        <Bank />
      </DefaultLayout>
    ),
  },
  {
    path: "/account/withdraw-history",
    element: (
      <DefaultLayout>
        <WithdrawHistory />
      </DefaultLayout>
    ),
  },
  {
    path: "/account/deposit-history",
    element: (
      <DefaultLayout>
        <DepositHistory />
      </DefaultLayout>
    ),
  },
  {
    path: "/account/mission-history",
    element: (
      <DefaultLayout>
        <MissionHistory />
      </DefaultLayout>
    ),
  },
  {
    path: "/notification",
    element: (
      <DefaultLayout>
        <Notification />
      </DefaultLayout>
    ),
  },
  {
    path: "auth/login",
    element: <Login />,
  },
  {
    path: "auth/register",
    element: <Register />,
  },
]);

createRoot(document.getElementById("root")).render(
  <App>
    <RouterProvider router={router} />
  </App>
);
